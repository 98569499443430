import * as React from 'react'
import { AiPopoverVm } from '../popover.types'
import { store } from 'store/index'
import { AsyncTaskOutput } from 'store/modules/async-tasks'

export class AiFocusSkillActionsVm implements AiPopoverVm {
  constructor(readonly source: string) {}

  readonly generateButtonText = 'Generate new suggestion'
  readonly onErrorMessage = 'Sorry there was a problem, please try again.'
  readonly onSuccessMessage = 'Done'
  readonly generatingMessage = 'This might take a few seconds.'

  async onSubmit(inputs: Record<string, unknown>) {
    if (
      !inputs.skillId ||
      typeof inputs.skillId !== 'string' ||
      !inputs.positionId ||
      typeof inputs.positionId !== 'string' ||
      !inputs.level ||
      typeof inputs.level !== 'number' ||
      typeof inputs.cache !== 'boolean'
    ) {
      return
    }

    const activeTask = await store.asyncTasks.create(
      {
        type: 'open_ai',
        step: 'focus-skill-actions',
        attributes: {
          skillId: inputs.skillId,
          positionId: inputs.positionId,
          level: inputs.level,
          cached: inputs.cache,
        },
      },
      { source: this.source }
    )

    if (activeTask.success && activeTask?.data?.asyncTasks) {
      const taskId = Object.keys(activeTask.data.asyncTasks)[0]
      return taskId
    }
  }

  content(output: AsyncTaskOutput) {
    const r = output.focusSkillActions?.output?.extracted.at(0)

    return (
      <>
        <p className="text-gray-600 font-bold text-xs">Suggested Action</p>
        {r?.items.map((a) => (
          <p key={Math.random()}>{a.action}</p>
        ))}
      </>
    )
  }

  readonly formContent = null
}
